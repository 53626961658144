.tab-slider-advanced-calc{
    position: absolute;
    height: calc(100% - 42px);
    bottom: 0;
    left: var(--left);
    width: var(--width);
    --items: 2;
    --left: calc((100%/var(--items))*(var(--index)-1));
    --width: calc(100%/var(--items));
    --index:0!important;
    pointer-events: none;
    background: #2ba770;
    transition: all 0.6s;
  }

  @media (max-width: 424px) {
    .tab-slider-advanced-calc {
      height: calc(100% - 15px);
    }
  }

  div div.active ~.tab-slider-advanced-calc{
    opacity: 1!important;
    border-bottom: 2px solid #a4f8d5;
  }

  div div:nth-of-type(1).active ~.tab-slider-advanced-calc{
    --index:0!important;
  }

  div div:nth-of-type(2).active ~.tab-slider-advanced-calc{
    --index:1!important;
  }
  div div:nth-of-type(3).active ~.tab-slider-advanced-calc{
    --index:2!important;
  }

  .tab-slider-advanced-calc-prod {
    position: absolute;
    height: calc(100% - 42px);
    bottom: 0;
    left: var(--left);
    width: var(--width);
    --items: 2;
    --left: calc((100%/var(--items))*(var(--index)-1));
    --width: calc(100%/var(--items));
    --index:0;
    opacity: 0;
    pointer-events: none;
    background: #2ba770;
    transition: all 0.6s;
  }

  @media (max-width: 424px) {
    .tab-slider-advanced-calc-prod {
      height: calc(100% - 15px);
    }
  }

  div div.active ~.tab-slider-advanced-calc-prod{
    opacity: 1!important;
    border-bottom: 2px solid #a4f8d5;
  }

  div div:nth-of-type(1).active ~.tab-slider-advanced-calc-prod{
    --index:1!important;
  }

  div div:nth-of-type(2).active ~.tab-slider-advanced-calc-prod{
    --index:2!important;
  }
  div div:nth-of-type(3).active ~.tab-slider-advanced-calc-prod{
    --index:3!important;
  }

  div.css-1mds97k-MuiInputBase-root-MuiInput-root:after {
    border-bottom: 2px solid #008060;
  }

  div.css-1mds97k-MuiInputBase-root-MuiInput-root.errors:after {
    border-bottom: 2px solid #de3618;
  }
  div.css-1mds97k-MuiInputBase-root-MuiInput-root.errors:before {
    border-bottom: 2px solid #de3618;
  }

  p.css-1wc848c-MuiFormHelperText-root.errors{
    color: #de3618;
    margin-left: 0px;
    text-align: initial;
  }

  p.MuiFormHelperText-root.MuiFormHelperText-sizeMedium.MuiFormHelperText-contained.errors.css-v7esy{
    color: #de3618;
    margin-left: 0px;
    text-align: initial;
  }
