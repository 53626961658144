body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.slide {
  position: absolute;
  height: 5px;
  bottom: 0;
  left: var(--left);
  width: var(--width);
  --items: 4;
  --left: calc((99%/var(--items))*(var(--index)-1));
  --width: calc(93%/var(--items));
  --index:0;
  opacity: 0;
  pointer-events: none;
  background: #1abc9c;
  transition: all 0.6s;
}

nav a.active ~.slide{
  opacity: 1;
}

nav a:nth-of-type(1).active ~.slide{
  --index:0;
}

nav a:nth-of-type(2).active ~.slide{
  --index:1;
}
nav a:nth-of-type(3).active ~.slide{
  --index:2;
}
nav a:nth-of-type(4).active ~.slide{
  --index:3;
  width: 25%;
}

.prod-slide {
  position: absolute;
  height: 5px;
  bottom: 0;
  left: var(--left);
  width: var(--width);
  --items: 4;
  --left: calc((99%/var(--items))*(var(--index)-1));
  --width: calc(93%/var(--items));
  --index:0;
  opacity: 0;
  pointer-events: none;
  background: #1abc9c;
  transition: all 0.6s;
}

nav a.active ~.prod-slide{
  opacity: 1!important;
}

nav a:nth-of-type(1).active ~.prod-slide{
  --index: 1!important;
}

nav a:nth-of-type(2).active ~.prod-slide{
  --index: 2!important;
}
nav a:nth-of-type(3).active ~.prod-slide{
  --index: 3!important;
}
nav a:nth-of-type(4).active ~.prod-slide{
  --index: 4!important;
  width: 25%;
}
