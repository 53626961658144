@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@500;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@500;700&family=Work+Sans:wght@500;700&display=swap");
*,
*:before,
*:after {
  box-sizing: inherit;
}
*:focus {
  outline: none;
}
html {
  height: 100%;
  font-family: "Roboto", "Work Sans", sans-serif;
  box-sizing: border-box;
}
body {
  height: 100%;
  width: 100%;
  background: #f5f5f5;
  margin: 0;
}
#___gatsby {
  display: flex;
  flex-direction: column;
  min-height: 100%;
}
#gatsby-focus-wrapper {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

/* nav hamburger */
.hamburger {
  width: 22px;
  height: 22px;
  display: block;
  position: relative;
}
.line {
  width: 22px;
  height: 2px;
  display: block;
  position: absolute;
  background: #ffffff;
  -moz-transition: all 0.25s ease-in-out;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}
.line-1 {
  top: 0;
}
.line-2 {
  margin-top: -3px;
  top: 50%;
}
.line-3 {
  bottom: 4px;
}
.active .line-1 {
  top: 50%;
  margin-top: -2px;
  -webkit-transform: rotate(315deg);
  -moz-transform: rotate(315deg);
  -o-transform: rotate(315deg);
  transform: rotate(315deg);
  background: #ffffff;
}
.active .line-2 {
  opacity: 0;
}
.active .line-3 {
  bottom: 50%;
  -webkit-transform: rotate(-315deg);
  -moz-transform: rotate(-315deg);
  -o-transform: rotate(-315deg);
  transform: rotate(-315deg);
  background: #ffffff;
}


@media (max-width: 424px) {
  fieldset.MuiFormControl-root.css-cfmb26-MuiFormControl-root {
    display: flex!important;
    justify-content: flex-start;
    margin-left: 30px;
    width: 320px;
}
  fieldset.MuiFormControl-root.css-1k6vn7k {
    display: flex!important;
    justify-content: flex-start;
    margin-left: 30px;
    width: 320px;
  }
  div.MuiFormGroup-root.MuiFormGroup-row.css-qfz70r-MuiFormGroup-root {
    margin-bottom: 0px!important;
    padding-top: 4px;
    display: flex;
  }
  div.MuiFormGroup-root.MuiFormGroup-row.css-p58oka {
    margin-bottom: 0px!important;
    padding-top: 4px;
    display: flex;
  }
  div.css-64noit {
    padding-right: 22px;
  }
  span.MuiRadio-root.MuiRadio-colorPrimary.MuiButtonBase-root.MuiRadio-root.MuiRadio-colorPrimary.PrivateSwitchBase-root.css-1ekhrsf-MuiButtonBase-root-MuiRadio-root {
    padding-right: 4px;
    padding-top: 9px;
    padding-bottom: 9px;
  }
  button.MuiButton-root.MuiButton-contained.MuiButton-containedPrimary.MuiButton-sizeMedium.MuiButton-containedSizeMedium.MuiButton-fullWidth.MuiButtonBase-root.css-1gmwvdv-MuiButtonBase-root-MuiButton-root {
    margin-top: 30px;
  }
  div.css-18m1ucn {
    width: 300px;
  }
  div.MuiAutocomplete-root.css-16awh2u-MuiAutocomplete-root {
    width: 100%;
  }
  div.MuiFormControl-root.MuiFormControl-fullWidth.MuiTextField-root.css-tjxpg3-MuiFormControl-root-MuiTextField-root {
    width: 355px;
    display: flex;
    margin: auto;
  }
  div.MuiFormControl-root.MuiFormControl-fullWidth.MuiTextField-root.css-j1x8pm-MuiFormControl-root-MuiTextField-root {
    align-items: center;
    width: 355px;
  }
  div.MuiFormControl-root.MuiFormControl-fullWidth.MuiTextField-root.css-yyczsr{
    width: 355px;
    display: flex;
    margin: auto;
  }
  div.MuiFormControl-root.css-1r55yx5-MuiFormControl-root {
    width: 355px;
    padding-top: 20px;
  }
  div.MuiFormControl-root.css-tsfidb {
    width: 355px;
    padding-top: 20px;
  }
  div.MuiFormControl-root.css-cfmb26-MuiFormControl-root {
    width: 355px;
  }
  div.MuiFormControl-root.MuiTextField-root.css-1omhc8w-MuiFormControl-root-MuiTextField-root {
    width: 90%;
  }
  div.MuiFormControl-root.MuiTextField-root.css-1y8edn-MuiFormControl-root-MuiTextField-root {
    width: 100%;
  }
  div.MuiFormControl-root.MuiTextField-root.css-1bohmbq {
    width: 100%;
  }
  .custom-tooltip-class {
    width: 260px;
  }
}

svg.market-is-open:hover {
  color: #ffffff
}
svg.market-is-closed:hover {
  color: #434d56
}
svg.operation-tooltip-icon:hover {
  color: #373f49
}

@media (min-width: 900px) {
  div.css-bgaxcy > :not(style) + :not(style) {
    margin: 0px 0px 0px 12px;
  }
  div.css-tqw2cc-MuiStack-root > :not(style) + :not(style) {
    margin: 0px 0px 0px 12px;
  }
}

.tab-slider-prod {
  position: absolute;
  height: calc(100% - 42px);
  bottom: 0;
  left: var(--left);
  width: var(--width);
  --items: 3;
  --left: calc((100%/var(--items))*(var(--index)-1));
  --width: calc(100%/var(--items));
  --index:0;
  opacity: 0;
  pointer-events: none;
  background: #2ba770;
  transition: all 0.6s;
}

@media (max-width: 424px) {
  .tab-slider-prod {
    height: calc(100% - 15px);
  }
}

div div.active ~.tab-slider-prod{
  opacity: 1!important;
  border-bottom: 2px solid #a4f8d5;
}

div div:nth-of-type(1).active ~.tab-slider-prod{
  --index:1!important;
}

div div:nth-of-type(2).active ~.tab-slider-prod{
  --index:2!important;
}
div div:nth-of-type(3).active ~.tab-slider-prod{
  --index:3!important;
}

.tab-slider{
  position: absolute;
  height: calc(100% - 42px);
  bottom: 0;
  left: var(--left);
  width: var(--width);
  --items: 3;
  --left: calc((100%/var(--items))*(var(--index)-1));
  --width: calc(100%/var(--items));
  --index:0!important;
  pointer-events: none;
  background: #2ba770;
  transition: all 0.6s;
}

@media (max-width: 424px) {
  .tab-slider {
    height: calc(100% - 15px);
  }
}

div div.active ~.tab-slider{
  opacity: 1!important;
  border-bottom: 2px solid #a4f8d5;
}

div div:nth-of-type(1).active ~.tab-slider{
  --index:0!important;
}

div div:nth-of-type(2).active ~.tab-slider{
  --index:1!important;
}
div div:nth-of-type(3).active ~.tab-slider{
  --index:2!important;
}

/* modal */
.react-responsive-modal-modal.custom-modal {
  padding: 0;
  border-radius: 2px;
  min-width: 340px;
}
button.react-responsive-modal-closeButton {
  top: 20px;
  right: 20px;
}

input[type="search"]::-webkit-search-cancel-button {
  -webkit-appearance: none;
  height: 14px;
  width: 14px;
  display: block;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAYAAAA7MK6iAAAAn0lEQVR42u3UMQrDMBBEUZ9WfQqDmm22EaTyjRMHAlM5K+Y7lb0wnUZPIKHlnutOa+25Z4D++MRBX98MD1V/trSppLKHqj9TTBWKcoUqffbUcbBBEhTjBOV4ja4l4OIAZThEOV6jHO8ARXD+gPPvKMABinGOrnu6gTNUawrcQKNCAQ7QeTxORzle3+sDfjJpPCqhJh7GixZq4rHcc9l5A9qZ+WeBhgEuAAAAAElFTkSuQmCC);
  background-repeat: no-repeat;
  background-size: 14px;
}
input[type="search"]::-webkit-search-cancel-button:focus {
  cursor: pointer;
}
